import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner.component";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import DashboardCardComponent from "../../components/dashboard/dashboard-card.component";
import { getAllMetrics } from "../../services/metrics-api.service";

export default function AdminDashboard() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<any | null>({
    totalCompanies: 0,
    totalResumes: 0,
    totalUsers: 0,
    totalActiveAccounts: 0,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "");
    if (accessToken && (user || storedUser)) {
      handleGetAllMetrics(accessToken);
    }
  }, []);

  // =====================================================================================================================
  // GET ALL ITEMS FUNCTIONALITY
  // =====================================================================================================================

  const handleGetAllMetrics = async (token: any) => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllMetrics(token);
      // Handling the response
      if (response.success) {
        setMetrics({
          totalCompanies: response?.data?.data?.totalCompanies,
          totalResumes: response?.data?.data?.totalResumes,
          totalUsers: response?.data?.data?.totalUsers,
          totalActiveAccounts: response?.data?.data?.totalActiveAccounts,
        });
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="lg:px-24 lg:pt-12 min-h-screen">
        <h1 className="text-5xl text-slate-700 font-bold">Dashboard</h1>
        <div className="container mx-auto mt-20 mb-32">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
            <DashboardCardComponent
              title={"Total Companies"}
              value={metrics?.totalCompanies || 0}
              icon={"fa-regular fa-building text-blue-800"}
            />
            <DashboardCardComponent
              title={"Total Resumes"}
              value={metrics?.totalResumes || 0}
              icon={"fa-regular fa-id-card text-blue-800"}
            />
            <DashboardCardComponent
              title={"Total Users"}
              value={metrics?.totalUsers || 0}
              icon={"fa-regular fa-user text-blue-800"}
            />
            <DashboardCardComponent
              title={"Total Activated Accounts"}
              value={metrics?.totalActiveAccounts || 0}
              icon={"fa-solid fa-user-check text-blue-800"}
            />
          </div>
        </div>

        <div className="w-full grid grid-cols-1 xl:grid-cols-2">
          <div className="inline-block"></div>
          <div className="grow"></div>
        </div>
        <div className="h-32"></div>
      </div>

      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
