import React from "react";
import { Link } from "react-router-dom";
import { VIEWS } from "../routes/routes";
import logo from "../common/assets/images/logo.png";

export default function Footer() {
  return (
    <React.Fragment>
      <footer className="bg-footer w-full">
        <div className="w-full max-w-screen-xl mx-auto p-8">
          <div className="flex flex-col items-center justify-center sm:flex-row sm:items-start sm:justify-between pt-20">
            <div className="mb-4 sm:mb-0 text-center sm:text-left">
              <Link
                to={VIEWS.HOME}
                className="text-2xl font-semibold whitespace-nowrap dark:text-white"
              >
                <img src={logo} alt="" className="mx-auto sm:mx-0" />{" "}
              </Link>
            </div>
            <ul className="flex flex-col flex-wrap items-center mb-6 font-medium text-white">
              <div className="text-sm mb-10">
                <p className="text-white text-md font-bold">
                  Recruiter Helpline
                </p>
                <p className="text-white text-md font-bold mt-2">
                  <i className="text-white  fa-solid fa-phone mr-2" />
                  1800-102-5558
                </p>
                <p className="text-primary-light text-sm mt-5">
                  10:00 AM to 6:00 PM, Mon-Sat
                </p>
                <p className="text-primary-light text-sm">
                  Contact Us - Report a Problem
                </p>
              </div>
              <div className="flex flex-1 inline-block">
                <li>
                  <Link to={""} className="mr-4 hover:text-slate-300 text-4xl ">
                    <i className="fa-brands fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to={""} className="mr-4 hover:text-slate-300 text-4xl ">
                    <i className="fa-brands fa-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link to={""} className="mr-4 hover:text-slate-300 text-4xl ">
                    <i className="fa-brands fa-twitter"></i>
                  </Link>
                </li>{" "}
                <li>
                  <Link to={""} className="mr-4 hover:text-slate-300 text-4xl ">
                    <i className="fa-brands fa-instagram"></i>
                  </Link>
                </li>
              </div>
            </ul>
          </div>
          <span className="block text-sm text-gray-500 text-center mt-12 mb-2">
            © {new Date().getFullYear()}{" "}
            <Link to={VIEWS.HOME} className="hover:underline cursor-pointer">
              Vikalp
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </React.Fragment>
  );
}
